import { Button } from "@chakra-ui/react"
import React from 'react'
import { Link } from "react-router-dom"

const ActionButton = ({
  text,
  path,
  width = "auto",
  ...rest
}) => {
  return (
    <Button
      as={Link} to={path} width={width} {...rest}
      bg="dark" color="light" borderRadius={2}
      paddingInline={8} paddingBlock={6} textTransform="capitalize"
    >
      {text}
    </Button>
  )
}

export default ActionButton