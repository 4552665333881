import { Badge, Box, Flex, Stack, Text } from "@chakra-ui/react"
import React from 'react'
import ActionButton from "./ActionButton"

const BumpItem = ({
  status
}) => {
  return (
    <Box>
      <Badge
        display="block" bg={status === "new" ? "green" : "primary"} paddingBlock={2} textAlign="center" mb={4}
        paddingInline={4} color="light" fontSize="1rem" textTransform="capitalize"
      >
        {status}
      </Badge>

      <Stack spacing={2} textTransform="capitalize" mb={4}>
        <Flex justifyContent="space-between" color="text">
          <Text>8:39 pm</Text>
          <Text>25m</Text>
        </Flex>
        <Flex justifyContent="space-between" color="text">
          <Text>order# 220</Text>
          <Text>dine in</Text>
        </Flex>
      </Stack>

      <Stack>
        <Flex bg="light" paddingBlock={3} paddingInline={4} borderStart="5px solid" borderColor={status === "new" ? "green" : "primary"}>
          <Box width="40px">3x</Box>
          <Box flex="1">Lorem ipsum dolor sit amet consectetur</Box>
        </Flex>
        <Flex bg="light" paddingBlock={3} paddingInline={4} borderStart="5px solid" borderColor={status === "new" ? "green" : "primary"}>
          <Box width="40px">3x</Box>
          <Box flex="1">Lorem ipsum dolor sit amet consectetur</Box>
        </Flex>
        <Flex bg="light" paddingBlock={3} paddingInline={4} borderStart="5px solid" borderColor={status === "new" ? "green" : "primary"}>
          <Box width="40px">3x</Box>
          <Box flex="1">Lorem ipsum dolor sit amet consectetur</Box>
        </Flex>
        <Flex bg="light" paddingBlock={3} paddingInline={4} borderStart="5px solid" borderColor={status === "new" ? "green" : "primary"}>
          <Box width="40px">3x</Box>
          <Box flex="1">Lorem ipsum dolor sit amet consectetur</Box>
        </Flex>
        <Flex bg="light" paddingBlock={3} paddingInline={4} borderStart="5px solid" borderColor={status === "new" ? "green" : "primary"}>
          <Box width="40px">3x</Box>
          <Box flex="1">Lorem ipsum dolor sit amet consectetur</Box>
        </Flex>
      </Stack>

      <ActionButton text="bump" width="100%" mt={6} />
    </Box>
  )
}

export default BumpItem