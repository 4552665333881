const routes = {
  dashboard: "",
  dashboard_general: "dashboard",
  dashboard_branches: "dashboard/branches",
  dashboard_inventory: "dashboard/inventory",
  dashboard_call_center: "dashboard/call_center",
  orders: "orders",
  call_center: "call_center",
  customers: "customers",
  reports_sales: "reports/sales",
  reports_analysis: "reports/analysis",
  reports_payments: "reports/payments",
  reports_inventory_control: "reports/inventory_control",
  reports_inventory_history: "reports/inventory_history",
  inventory_items: "inventory/items",
  inventory_suppliers: "inventory/suppliers",
  inventory_transfers_requests: "inventory/transfers_requests",
  inventory_inventory_count: "inventory/inventory_count",
  inventory_purchases: "inventory/purchases",
  inventory_transfers: "inventory/transfers",
  inventory_production: "inventory/production",
  products_categories: "products/categories",
  products_products: "products/products",
  products_combos: "products/combos",
  products_gifts_cards: "products/gifts_cards",
  products_modifiers: "products/modifiers",
  products_modifiers_options: "products/modifiers_options",
  products_groups: "products/groups",
  management_users: "management/users",
  management_roles: "management/roles",
  management_branches: "management/branches",
  management_devices: "management/devices",
  management_discount: "management/discount",
  management_coupons: "management/coupons",
  management_promotion: "management/promotions",
  management_limited_events: "management/limited_events",
  management_more: "management/more",
  bump: "bump",
  hr: "hr",
  general_accounts: "general_accounts",
  fainance_accounts: "fainance_accounts"
};

export default routes;