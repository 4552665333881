import { Routes, Route } from "react-router-dom";
import Kds from "../features/kds/pages/Kds";

const Navigations = () => {
  return (
    <Routes>
      <Route path="" element={<Kds />} />
    </Routes>
  )
}

export default Navigations