import { Box, Flex, SimpleGrid, } from "@chakra-ui/react"
import routes from "../../../navigations/routes";
import { useTranslation } from "react-i18next";
import ActionButton from "../components/ActionButton";
import KdsItem from "../components/KdsItem";

const Kds = () => {
  const { t } = useTranslation();

  return (
    <Box paddingInline={4} pb={6}>
      <SimpleGrid
        spacingX={6} spacingY={12} columns={{ base: 1, md: 2 }} mb={4} position="sticky" top="0"
        bg="bg" paddingBlock={6}
      >
        <Flex alignItems="center" gap={2}>
          <ActionButton
            text={t("pages.kds.home")}
            path={routes.dashboard_general}
          />
          <ActionButton
            text={t("pages.kds.history")}
            path={routes.dashboard_general}
          />
        </Flex>
        <Flex alignItems="center" gap={2} justifyContent={{ base: "flex-start", lg: "flex-end" }}>
          <ActionButton
            text={t("pages.kds.previous")}
            path={routes.dashboard_general}
          />
          <ActionButton
            text={t("pages.kds.next")}
            path={routes.dashboard_general}
          />
        </Flex>
      </SimpleGrid>

      <SimpleGrid
        mt={8} spacing={6} columns={{ base: 1, md: 2, lg: 3, xl: 5 }} mb={4}
      >
        <KdsItem status="new" />
        <KdsItem status="changed" />
        <KdsItem status="new" />
        <KdsItem status="changed" />
        <KdsItem status="changed" />
        <KdsItem status="new" />
        <KdsItem status="changed" />
        <KdsItem status="new" />
        <KdsItem status="changed" />
        <KdsItem status="changed" />
      </SimpleGrid>
    </Box>
  )
}

export default Kds